html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --color-principal: :#FFE6E6
}

body {
  font-family: "Cardo", "inter", sans-serif;
  color: aliceblue;
  line-height: 1;
  overflow-x: hidden;
}

.letters {
  height: 150px;
  padding-top: 20px;
  padding-left: 50px;
}

.main-header {
  display: flex;
  width: 100%;
  font-size: 24px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.116);
}

nav {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
}

nav a {
  display: flex;
  font-size: 32px;
  justify-content: space-between;
  color: #FFE6E6;
  align-items: flex-end;
  text-decoration: none;
  text-transform: uppercase;
}

nav a:hover {
  text-decoration: underline;
}

.couple-names {
  /* height: 200px;
        margin: 80px;*/
  font-family: "Alex Brush", "Rouge Script", cursive;
  letter-spacing: 12px;
  font-size: 200px;
  font-weight: 400;
  padding: 65px 0;
  color: #FFE6E6;
  /*text-shadow: 0 0 7px #d819a2, 0 0 10px #3d1e34, 0 0 21px #3d1e34,
          0 0 42px #cd1997b9, 0 0 82px #cd19979f, 0 0 92px #cd199777,
          0 0 102px #e77dc8, 0 0 151px #ffffff;*/
}

.section {
  min-height: 100vh;
  height: fit-content;
  width: 100vw;
  padding: 200px;
}

.section#page-gallery {
  height: 100%;
  width: 100vw;
  padding: 200px;
}

.section:nth-child(even) {
  background-color: #ffebeb;
  /* MORADO #e469bf34 TIERRA #f8f3ee ROSA #ffebeb*/
}

.section:nth-child(odd) {
  background-color: #fff3f3;
  /* MORADO #e469bf25 TIERRA #f1e7de ROSA #fff3f3*/
}

.section#page-photo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("assets/evayjokinbeso.jpg");
  background-size: cover;
  background-position: center;
}

.section-text {
  font-size: 30px;
  letter-spacing: 5px;
}

.section#page-program {
  position: relative;
  font-size: 28px;
  color: #3d1e34;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.img-manoarriba {
  position: absolute;
  right: -85px;
  bottom: 0;
  height: 700px;
}

.section-column {
  flex: 1;
  border-right: dotted;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-column:last-child {
  border-right: none;
}

.section-column h2 {
  text-align: center;
  padding-bottom: 50px;
}



.section#page-address {
  position: relative;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  color: #3d1e34;
  justify-content: space-evenly;
}

.section-column h2 {
  text-align: center;
  /* padding-bottom: 10px; */
}

.transport {
  margin-top: 40px;
}

.section-column p {
  text-align: center;
  padding: 30px;
  line-height: 1.6;
}

.section-column ul {
  margin: 0 5em;
  text-align: justify;

}

ul li {
  margin-bottom: 25px;
}

.google-map {
  width: 70%;
  height: 80%;
  /* padding-bottom: 50%; */
  position: relative;
}

.google-map iframe {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.google-map__text {
  padding: 30px 0;
}

.google-map__text p {
  padding: 10px 0;
}

/* .hotel-icon {
        height: 90px;
        margin: 50px 0;
      } */

.section#page-rsvp {
  position: relative;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  color: #3d1e34;
  justify-content: center;
  align-items: center;
  height: fit-content;
  min-height: 100vh;
}

.label {
  margin-top: 50px;
}

.rsvp-header {
  /* align-self: flex-start; */
}

.form-container {
  border: 4px solid white;
  border-radius: 4px;
  padding: 20px 40px;
  width: fit-content;
  line-height: 1.1;
  margin-top: 40px;
}

.form-container.acompanante:first-child {
  border-right: 1px solid transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-container.acompanante:last-child {
  border-left: 1px solid transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn {
  /* display: flex; */
  /* justify-content: space-evenly; */
  position: absolute;
  padding: 30px 50px 30px 50px;
  background-color: #FFE6E6;
  font-size: 24px;
  font-family: "Cardo";
  color: #3d1e34;
  border-radius: 4px;
  bottom: 150px;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn:hover {
  text-decoration: underline;
  transition: all 0.2s;
  cursor: pointer;
}



.section#login {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: transparent;

}

.login__backdrop {
  background-color: #00000090;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.login-box {
  background-color: #FFE6E6;
  height: fit-content;
  padding: 40px 200px 90px 200px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logomorado {
  height: 50px;
  top: 0;
}

.btn-access {
  background-color: #3d1e34;
  color: #FFE6E6;
  padding: 20px 120px 20px 120px;
  font-size: 24px;
  font-family: "Cardo";
  border-radius: 4px;
  margin-top: 40px;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn-access:hover {
  text-decoration: underline;
  transition: all 0.2s;
}

.item-input-text.login {
  border: 2.5px solid #3d1e34;
  width: 100%;
  height: 65px;
  border-radius: 4px;
  margin-top: 40px;
  background-color: #FFE6E6;
  font-size: 24px;
  padding-left: 50px;
}

input[type=text].login {
  /* width: 1px; */
  background-image: url('assets/iconperson.png');
  background-size: 20px;
  background-position: 10px;
  /* Adjusts the position of the background image */
  background-repeat: no-repeat;
}

input[type=password].login {
  background-image: url('assets/iconlock.png');
  background-size: 20px;
  background-position: 10px;
  /* Adjusts the position of the background image */
  background-repeat: no-repeat;
}

.form__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 50px;
}

.form__btn {
  border: 1px solid #3d1e34;
  border-radius: 4px;
  padding: 0.5em 2em;
  width: 200px;
  margin-top: 1.5em;
  background-color: #3d1e34;
  color: #FFE6E6;
  font-size: 18px;
  cursor: pointer;
}

.form__btn:disabled{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;

}

.form__error-list{
  color: red;
  font-size: 20px;
  padding: 1em;
  
}

.form__error-list__item{
  list-style-type: "- ";
  margin: 0;
}

.item-input-text {
  height: 30px;
  width: 250px;
  padding: 2px 4px;
  border: 4px solid white;
  background-color: #ffffff;
}

.form-item {
  display: flex;
  flex-direction: column;
  width: 50%;
  line-height: 1.2;
  /* margin-bottom: 40px; */
}

.form-item textarea {
  min-height: 50px;
  max-height: 200px;
  width: 250px;
  max-width: 300px;
}

.form-component {
  display: flex;
  flex-direction: row;
}

.form-checkbox input {
  width: 20px;
  height: 20px;
  accent-color: #612550;
  margin-right: 10px;
}

.img-corazon {
  position: absolute;
  left: 0;
  bottom: 10px;
  /* height: 700px; */
  width: 25%;
}


.section#page-gifts {
  position: relative;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3d1e34;
  justify-content: space-evenly;
  line-height: 3;
}

.bank {
  margin-top: 50px;
  font-size: 65px;
}

.text {
  z-index: 10;
  text-align: center;
}

.img-circular {
  position: absolute;
  height: 80%;
  filter: opacity(0.3);
}

.photo-gallery-title {
  text-align: center;
  font-size: 50px;
  padding-bottom: 50px;
  color: #3d1e34;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 15px;
}



.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery__img:hover {
  transform: scale(1.1);
  transition: all 0.2s;
}

.gallery__item--1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.gallery__item--2 {
  grid-column: 3 / 5;
  grid-row: 1 / 3;
}

.gallery__item--3 {
  grid-column: 5 / 9;
  grid-row: 1 / 6;
}

.gallery__item--4 {
  grid-column: 1 / 5;
  grid-row: 3 / 6;
}

.gallery__item--5 {
  grid-column: 1 / 5;
  grid-row: 6 / 9;
}



.section#save-date{
  font-size: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #3d1e34;
  justify-content: space-between;
  line-height: 1.5;
  text-align: justify;
  padding: 1em;
  height: 100vh;
}

.save-image {
  height: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: all 0.5s;
}

.save-image:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.save-text{
padding: 0 70px;
}

.section#photos {
  padding: 0;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  text-align: center;
  color: #3d1e34;
  /* justify-content: space-between; */
  line-height: 1.5;
  text-align: justify;
  height: fit-content;
  min-height: 0;
}

.photos-cont {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 100px;
}

.eva,
.jokin {
 height: 500px;
  margin-bottom: 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: all 0.5s;
}

.eva:hover,
.jokin:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}




@media (max-width: 1600px) {
  .letters {
    /* height: 150px;
          padding-top: 20px; */
    padding-left: 10px;
  }

  nav a {
    font-size: 24px;

  }

  .couple-names {
    padding: 36px 0
  }

  .section {
    padding: 100px;
  }

  .section#page-program {
    font-size: 24px;

  }

  .section-column p {
    text-align: center;
    padding: 20px;
    line-height: 1.3;
  }


  .section-column h2 {
    padding-bottom: 30px;
  }

  .img-manoarriba {
    position: absolute;
    left: 50px;
    bottom: 0;
    height: 500px;
  }



  .section#page-address {
    font-size: 24px;

  }


  .transport {
    margin-top: 20px;
  }



  .section-column ul {
    margin: 0 3em;
    text-align: justify;

  }

  ul li {
    margin-bottom: 20px;
  }


  .section#page-rsvp {
    font-size: 24px;

  }

  .label {
    margin-top: 25px;
    margin-bottom: 10px;
  }


  .section#page-gifts {
    font-size: 36px;

  }

  .bank {
    margin-top: 35px;
    font-size: 48px;
  }






}

@media (max-width:1400px) {
  .main-header {
    display: none;

  }


  .couple-names {
    /* height: 200px;
          margin: 80px;*/
    font-size: 125px;
    padding: 25px 0;

  }

  .section-text {
    font-size: 30px;
    letter-spacing: 5px;
    line-height: 1.1;
    text-align: center;
  }

  .section-text.date {
    font-size: 20px;
    letter-spacing: 5px;
    line-height: 1.1;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .section {
    height: 100vh;
    width: 100vw;
    padding: 50px;
  }

  .btn {
    padding: 15px 20px;
    bottom: 50px;
  }

  .section#page-program {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: fit-content;
  }

  .img-manoarriba {
    display: none;
  }

  .section-column {
    flex: 1;
    border-right: none;
    border-bottom: dotted;
    margin-bottom: 40px;
  }

  .section-column:last-child {
    border-right: none;
    border-bottom: none;
  }

  .section#page-address {
    font-size: 24px;
    flex-direction: column;
    height: fit-content;

  }

  .transport {
    margin-top: 20px;
  }

  .section-column p {
    text-align: center;
    padding: 20px;
    line-height: 1.6;
  }

  .section-column ul {
    margin: 0 0em;
    text-align: left;

  }


  .google-map {
    width: 90%;
    height: 500px;

  }



  .section#page-rsvp {
    position: relative;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    color: #3d1e34;
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-height: 100vh;
  }

  .label {
    margin-top: 40px;
    width: 100%;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 1.2;
    /* margin-bottom: 40px; */
  }

  .item-label {
    width: 100%;
    /* text-align: center; */
  }

  .rsvp-header {
    /* align-self: flex-start; */
    text-align: center;
    margin-bottom: 10px;
  }

  .form-component {
    flex-direction: column;
  }

  .form-container {
    border: 4px solid white;
    border-radius: 4px;
    padding: 20px 40px;
    width: fit-content;
    line-height: 1.1;
    margin-top: 0px;
  }

  .form-container.acompanante:first-child {
    border-right: 4px solid white;
    border-top-right-radius: 4px;

    border-bottom: 1px solid transparent;
  }

  .form-container.acompanante:last-child {
    border-left: 4px solid white;
    border-bottom-left-radius: 4px;
    border-top: 1px solid transparent;

  }


  .img-corazon {
    display: none;
  }

  .img-circular {
    position: absolute;
    width: 80%;
    height: auto;
    max-height: 80%;
  }

  .section#page-gifts {
    font-size: 24px;
    line-height: 1.5;
  }

  .bank {
    margin-top: 50px;
    font-size: 24px;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .btn {
    max-width: 80%;
    font-size: 18px;
    bottom: 20px;
  }

  
.section#save-date{
  font-size: 26px;
  flex-direction: column;
  height: fit-content;
}

.save-image {
  height: auto;
  width: 95%;
}


.save-text{
  padding: 0;
}

.section#photos {

  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.photos-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 20px 30px;
}

.eva,
.jokin {
  width: 95%;
  height: auto;
}



}